<template>
    <div class="container">
        <div class="header">
            <h1 :style="{'font-size':bigTitleSize}">{{ nameScreen }}</h1>
        </div>
        <div class="main">
            <div class="mainTitle" @click="backClick">{{ nameScreen }}</div>
            <div class="mainBox">
                <div class="itemBox position1" @click="boxChange1">
                    <img class="imgs" src="../../assets/menu2/chanye2.png" alt="">
                    <p class="itemInfo">产业大数据</p>
                    <div class="fontB"></div>
                </div>
                <div class="itemBox position2" @click="boxChange2">
                  <img class="imgs" src="../../assets/menu2/yuan1.png" alt="">
                    <p class="itemInfo">智慧园区</p>
                    <div class="fontB"></div>
                </div>
                <div class="itemBox position3" @click="boxChange3">
                  <img class="imgs" src="../../assets/menu2/yan1.png" alt="">
                    <p class="itemInfo">研究院</p>
                    <div class="fontB"></div>
                </div>
                <div class="itemBox position4" @click="boxChange4">
                  <img class="imgs" src="../../assets/menu2/gong1.png" alt="">
                    <p class="itemInfo">智慧工厂</p>
                    <div class="fontB"></div>
                </div>
                <div class="itemBox position5" @click="boxChange5">
                  <img class="imgs" src="../../assets/menu2/bao1.png" alt="">
                    <p class="itemInfo">保鲜系统</p>
                    <div class="fontB"></div>
                </div>
            </div>
          <div class="bottom_bg"></div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      nameScreen: "物联网综合系统",
      bigTitleSize: "28px",
    }
  },
  mounted() {
    this.getPowerName();
  },
  methods:{
    getPowerName() {
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100,
      }).then((res) => {
        if (res.data.state == "success") {
          this.nameScreen = res.data.datas[0].name;
          //let menus = JSON.parse(res.data.datas[0].conf1);
          //this.centerName = menus[0] || "综合大屏";
          /*this.btn1 = menus[1] == 0 ? 0 : 1;
          this.btn2 = menus[2] == 0 ? 0 : 1;
          this.btn3 = menus[3] == 0 ? 0 : 1;
          this.btn4 = menus[4] == 0 ? 0 : 1;
          this.btn5 = menus[5] == 0 ? 0 : 1;
          this.btn6 = menus[6] == 0 ? 0 : 1;
          this.btn7 = menus[7] == 0 ? 0 : 1;
          this.btn8 = menus[8] == 0 ? 0 : 1;
          this.btn9 = menus[9] == 0 ? 0 : 1;
          this.btn10 = menus[10] == 0 ? 0 : 1;*/
          this.resizeBigTitle(this.nameScreen);
        }else{
          console.log(res)
        }
      });
    },
    resizeBigTitle(name) {
      let titLength = name.length;
      if (titLength <= 8) {
        this.bigTitleSize = "40px";
      } else if (titLength <= 10) {
        this.bigTitleSize = "38px";

      } else if (titLength <= 12) {
        this.bigTitleSize = "36px";

      } else {
        this.bigTitleSize = "30px";

      }
    },
    boxChange1() {//产业大数据
      this.$router.push("/chanye");
    },
    boxChange2() {//园区
      this.$router.push("/cloud2");
    },
    boxChange3() {//研究院
      this.$router.push("/yanjiuyuan");
    },
    boxChange4() {//工厂
      this.$router.push("/factory");
    },
    boxChange5() {//保鲜
      this.$router.push("/baoxian");
    },
    backClick() {
      this.$router.push("/info");
    },
  }
}
</script>

<style scoped lang="less">

*{
    margin: 0;
    padding: 0;
}
.container {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/menu2/bg4.jpg") no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.header{
  width: 100%;
  height: 10vh;
  background: url("../../assets/menu2/title2.png") no-repeat;
  background-size: 100% 100%;

  h1 {
    text-align: center;
    line-height: 7vh;
    letter-spacing: 3px;
  }
}
.main {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: url("../../assets/menu2/bg2.png") no-repeat;
  background-size: 100% 100%;

  .bottom_bg {
    width: 100%;
    height: 80px;
    background: url("../../assets/menu2/bg3.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    position: fixed;
  }
}
.mainTitle{
    font-size: 36px;
    letter-spacing: 3px;
    position: absolute;
    left: 50%;
    bottom: 10vh;
    transform: translate(-50%);
  font-weight: bold;
  cursor: pointer;
}
.mainBox{
    width: 87%;
    height: 60vh;
    margin: 17vh auto 0;
    position: relative;
}
.itemBox {
  width: 8vw;
  height: 20vh;
  background: url("../../assets/menu2/boxBg2.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  cursor: pointer;
}
.position1{
    position: absolute;
    left: 50%;
    top: 0vh;
    transform: translate(-50%);
}
.position2{
    position: absolute;
    left: 16vw;
    top: 9vh;
}
.position3{
    position: absolute;
    right: 16vw;
    top: 9vh;
}
.position4{
    position: absolute;
    left: 1vw;
    bottom: 0vh;
}
.position5{
    position: absolute;
    right: 1vw;
    bottom: 0vh;
}
.imgs{
    width: 3vw;
    height: 6vh;
    object-fit: contain;
    margin-top: 2vh;
}
.itemInfo{
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    font-size: 2vh;
    font-weight: bold;
    line-height: 5.5vh;
    z-index: 1;
}
.fontB{
    width: 66%;
    height: 2vh;
    position: absolute;
    top: 11vh;
    left: 50%;
    transform: translate(-50%);
    background: url("../../assets/menu2/font.png") no-repeat;
    background-size: 100% 100%;
    opacity: 0.75;
}

</style>